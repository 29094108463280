import PropTypes from 'prop-types';
import { Link, Loader } from 'components';
import axios from 'config/customAxios';
import { notify, queryFormatter } from 'utils';
import { useState } from 'react';
import DomainsTable from './components/DomainsTable/DomainsTable';
import ViewBy from './components/ViewBy/ViewBy';
import styles from './BrandingCustomers.module.scss';
import HeaderActions from './components/HeaderActions/HeaderActions';

const BrandingCustomers = ({ domains, domainsEmpty, setDomains, isLoading, baseUrl }) => {
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState([]);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const queryViewBy = domains?.queryConfig?.viewBy;

  const paginationData = {
    queryConfig: domains?.queryConfig,
    updateAction: setDomains,
    sortConfig: domains?.queryConfig?.orderBy?.split(/%20| /),
    baseUrl,
  };

  if (isLoading) {
    return (
      <div className={styles.branding}>
        <Loader id="branding-customer-loading" />
      </div>
    );
  }

  const changeDomainBrandingStatus = async (isHeader, domain) => {
    try {
      const domainIds = domain ? [domain.domainId] : selectedUsers.map((d) => d.domainId);

      if (isHeader) {
        setHeaderLoading(true);
      } else {
        setBtnLoadingCurrent((prevState) => [...prevState, domain.domainId]);
      }
      await axios.post(`/api/Company/ToggleCustomerDomainsBrandingStatus`, {
        isActive: domain ? !domain.status : queryViewBy !== 'Enabled',
        domainIds,
      });
      const queryConfig = {
        ...domains.queryConfig,
      };
      const res = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = res.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
      setDomains({ data: res.data, pageMeta, queryConfig });
      if (isHeader && selectedUsers.length > 1) {
        notify.success(`Branding Status is ${domain.status ? 'disabled' : 'enabled'} for domains`);
      } else {
        notify.success(
          `Branding Status is ${domain.status ? 'disabled' : 'enabled'} for ${
            domain ? domain?.dnsName : selectedUsers[0]?.dnsName
          }`,
        );
      }
    } catch (err) {
      notify.error(err?.response?.data || err?.message);
    } finally {
      if (isHeader) {
        setHeaderLoading(false);
        setSelectedUsers([]);
      } else {
        setBtnLoadingCurrent((prevState) => prevState.filter((id) => id !== domain.domainId));
      }
    }
  };

  if (domainsEmpty) {
    return (
      <div className={styles.noDomains}>
        <div className={styles.noDomainsTitle}>No Customer Domains added</div>
        <div className={styles.noDomainsLink}>
          Pleasee&nbsp;
          <Link href="https://www.trugrid.com/docs/addcustomerdomain" external bold>
            add customer domains
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      {selectedUsers.length > 0 ? (
        <HeaderActions
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          domains={domains}
          changeDomainBrandingStatus={changeDomainBrandingStatus}
          queryViewBy={queryViewBy}
          headerLoading={headerLoading}
        />
      ) : (
        <div className={styles.viewBy}>
          <ViewBy
            queryViewBy={queryViewBy}
            totalCount={domains?.pageMeta?.TotalCount}
            paginationData={paginationData}
          />
        </div>
      )}
      <DomainsTable
        domains={domains}
        setDomains={setDomains}
        baseUrl={baseUrl}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        queryViewBy={queryViewBy}
        btnLoadingCurrent={btnLoadingCurrent}
        changeDomainBrandingStatus={changeDomainBrandingStatus}
      />
    </div>
  );
};

BrandingCustomers.propTypes = {
  domains: PropTypes.object,
  domainsEmpty: PropTypes.bool,
  setDomains: PropTypes.func,
  isLoading: PropTypes.bool,
  baseUrl: PropTypes.string,
};

export default BrandingCustomers;
