/* eslint-disable react/no-array-index-key */
import { memo, useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  LoaderSmallIcon,
  AngleUpIcon,
  FileDownloadIcon,
  LinkCopyIcon,
  CloseIcon,
  TrashIcon,
  CheckIcon,
  AngleDownIcon,
} from 'assets/img';
import { CSSTransition } from 'react-transition-group';
import Button from 'components/Button/Button';
import styles from './DropdownArray.module.scss';

const ICONS_MAP = {
  download: FileDownloadIcon,
  copy: LinkCopyIcon,
  cancel: CloseIcon,
  delete: TrashIcon,
};

const DropdownArray = ({
  id,
  label,
  options,
  optionsDisabled,
  openIcon = true,
  icon,
  onChange,
  defaultValue,
  defaultValueHtml,
  isSmall,
  isSearch,
  isButton,
  isButton32,
  isDisabled,
  isFilter,
  strightRight,
  btnTitle,
  customField,
  onCustomEvent,
  style,
  className,
  width,
  optionsClassName,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef();
  const nodeRef = useRef();

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const toggleOpenDropdown = () => {
    if (isOpen && filteredOptions.length !== options.length) {
      setTimeout(() => setFilteredOptions(options), 200);
    }
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    if (filteredOptions.length !== options.length) setFilteredOptions(options);
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => onClose());

  const onSearchHandler = (e) => {
    const { value } = e.target;

    if (value) {
      const result = options.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
      setFilteredOptions(result);
    } else {
      setFilteredOptions(options);
    }
  };

  const onSelectHandler = (value, disabled, e) => {
    if (disabled) return false;

    e.stopPropagation();

    if (isFilter) {
      setIsLoading(true);
      onChange(value).then(() => setIsLoading(false));
    } else {
      onChange(value);
    }

    onClose();
  };

  const wrapperClasses = cn(styles.dropdown, {
    [styles.dropdownTypeButton]: isButton,
  });

  return (
    <div id={id} className={wrapperClasses} style={style} disabled={isDisabled || isLoading} ref={dropdownRef}>
      {label && <div className={styles.dropdownLabel}>{label}</div>}
      {isButton ? (
        <Button
          variant="primary"
          className={cn(styles.dropdownIsButton, {
            [styles.dropdownIsButtonActive]: isOpen,
            [className]: className,
          })}
          iconPosition="right"
          style={{ width }}
          isDisabled={isDisabled}
          icon={isLoading ? <LoaderSmallIcon id="loading-dropdown" /> : isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          size={isButton32 ? '32' : '40'}
          onClick={toggleOpenDropdown}>
          <span className={styles.dropdownValue}>
            {icon && icon}
            {isButton || isButton32 ? btnTitle : defaultValueHtml || defaultValue}
          </span>
        </Button>
      ) : (
        <button
          type="button"
          style={{ width }}
          className={cn(styles.dropdownButton, {
            [styles.dropdownButtonActive]: isOpen,
            [styles.dropdownButtonSmall]: isSmall,
            [styles.dropdownButtonIcon]: icon,
            [styles.dropdownButtonStrightRight]: strightRight,
            [className]: className,
          })}
          onClick={toggleOpenDropdown}>
          {icon && icon}
          <span className={styles.dropdownValue}>
            {isButton || isButton32 ? btnTitle : defaultValueHtml || defaultValue}
          </span>
          {openIcon && (isLoading ? <LoaderSmallIcon id="loading" /> : isOpen ? <AngleUpIcon /> : <AngleDownIcon />)}
        </button>
      )}
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}>
        <div
          className={cn(styles.dropdownOptions, { [optionsClassName]: optionsClassName })}
          style={{ width }}
          ref={nodeRef}>
          {isSearch && (
            <input
              type="search"
              className={styles.dropdownOptionsSearch}
              placeholder="Search..."
              onChange={onSearchHandler}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => {
              if (option === customField) {
                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={customField}
                    className={cn(styles.dropdownOptionsItem, styles.dropdownOptionsItemCustom)}
                    onClick={onCustomEvent}>
                    {option}
                  </button>
                );
              }
              if (option.icon) {
                const Icon = ICONS_MAP[option.icon];
                const optionDisabled = optionsDisabled?.includes(option.value);

                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={option.id}
                    className={cn(styles.dropdownOptionsItem, styles.dropdownOptionsItemIcon, {
                      [styles.dropdownOptionsItemDisabled]: optionDisabled,
                    })}
                    onClick={(e) => onSelectHandler(option.value, optionDisabled, e)}>
                    <Icon /> {option.value}
                  </button>
                );
              }
              if (typeof option === 'object' && option.value && option.id) {
                const optionDisabled = optionsDisabled?.includes(option.value);
                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={option.id}
                    className={cn(styles.dropdownOptionsItem, {
                      [styles.dropdownOptionsItemDisabled]: optionDisabled,
                    })}
                    onClick={(e) => onSelectHandler(option, optionDisabled, e)}>
                    {option.value}
                  </button>
                );
              }
              return (
                <button
                  type="button"
                  id={option?.id}
                  key={index}
                  onClick={(e) => onSelectHandler(option, false, e)}
                  className={cn(styles.dropdownOptionsItem, {
                    [styles.dropdownOptionsItemActive]: option === defaultValue,
                    [styles.dropdownOptionsItemDisabled]: optionsDisabled?.includes(option),
                  })}>
                  {option}
                  {option === defaultValue && <CheckIcon />}
                </button>
              );
            })
          ) : (
            <div className={styles.dropdownNoResults}>No results found</div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownArray.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  openIcon: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSearch: PropTypes.bool,
  isButton: PropTypes.bool,
  isButton32: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFilter: PropTypes.bool,
  strightRight: PropTypes.bool,
  btnTitle: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  customField: PropTypes.string,
  onCustomEvent: PropTypes.func,
  options: PropTypes.array.isRequired,
  optionsDisabled: PropTypes.array,
  defaultValue: PropTypes.string,
  defaultValueHtml: PropTypes.element,
  icon: PropTypes.element,
  style: PropTypes.any,
  width: PropTypes.string,
  className: PropTypes.any,
  optionsClassName: PropTypes.any,
};

DropdownArray.displayName = 'DropdownArray';

export default memo(DropdownArray);
