import PropTypes from 'prop-types';
import styles from './MfaNameCell.module.scss';

const MfaNameCell = ({ displayName, upn }) => (
  <div className={styles.nameCell}>
    <p className={styles.nameCellValue}>{displayName}</p>
    <p className={styles.email}>{upn}</p>
  </div>
);

MfaNameCell.propTypes = {
  displayName: PropTypes.string,
  upn: PropTypes.string,
};

export default MfaNameCell;
