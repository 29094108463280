import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import styles from './RouteLeavingGuard.module.scss';

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation, modalConfig }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        onRequestClose={closeModal}
        contentLabel={modalConfig.title}
        cancelTitle={modalConfig.btnClose}
        cancelButtonClass={styles.modalCancel}
        actionFunc={handleConfirmNavigationClick}
        actionTitle={modalConfig.btnAccept}
        variantBtn="deleteOutline"
      >
        {modalConfig.message}
      </Modal>
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired,
  modalConfig: PropTypes.object,
};

export default RouteLeavingGuard;
