/* eslint-disable react/function-component-definition */
import { ArrowLeftIcon } from 'assets/img';
import { Button, DomainStatusMsg } from 'components';
import { getDomainStatus, getPageName, isSentryDeleted } from 'helpers';
import { useContext } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { DomainContext, getUserRole, queryFormatter, truncateString, UserContext } from 'utils';
import styles from './withCustomers.module.scss';

export const withCustomers = (Component) => () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    domain: selectedDomain,
    onUpdateDomain: onUpdateSelectedDomain,
    domains,
    onUpdateDomains: setDomains,
  } = useContext(DomainContext);

  if (!selectedDomain) {
    return <Redirect to="/customer-management" />;
  }

  const { user, onUpdateUser } = useContext(UserContext);
  const { isDomainNotActivated } = getDomainStatus(selectedDomain);
  const sentryDeleted = isSentryDeleted(selectedDomain);
  const userRole = getUserRole(user);

  const refreshPageUrlCustomers = queryFormatter('/api/v2/Domains/GetCustomerDomains', domains?.queryConfig);

  const onBack = () => {
    history.push('/customer-management');
    onUpdateSelectedDomain(null);
  };

  const onUpdateAppPublishing = (status, isCurrent) => {
    const selectedDomainNew = JSON.parse(JSON.stringify(selectedDomain));
    const domainsNew = JSON.parse(JSON.stringify(domains));

    if (isCurrent) {
      selectedDomainNew.appPublishingCurrent = status;
    } else {
      selectedDomainNew.isAppPublishingEnabled = status;
      delete selectedDomainNew.appPublishingCurrent;
    }

    domainsNew.data = domainsNew.data.map((d) => (d.id === selectedDomain.id ? { ...selectedDomainNew } : { ...d }));

    onUpdateSelectedDomain(selectedDomainNew);
    setDomains(domainsNew);
  };

  const pageName = getPageName(pathname);

  if (isDomainNotActivated && !pathname.includes('device-management') && !pathname.includes('domain-management')) {
    return (
      <div className={styles.customers}>
        <div>
          <div className={styles.customersHeader}>
            <div className={styles.customersWrap}>
              <Button variant="link" icon={<ArrowLeftIcon />} className={styles.customersWrapButton} onClick={onBack}>
                Back to Customer Domains
              </Button>
              <h3 className={styles.customersTitle}>({truncateString(selectedDomain?.dnsName, false, 80)})</h3>
            </div>
          </div>
          <DomainStatusMsg domainData={selectedDomain} isSentryDeleted={sentryDeleted} pageName={pageName} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.customers}>
      <div className={styles.customersDomain}>
        {!(
          pageName === 'Remember me' ||
          pageName === 'Shortcut Management' ||
          pageName === 'Azure Management' ||
          pageName === 'Timeout Management' ||
          pageName === 'Password Management' ||
          pageName === 'Phone Management'
        ) && (
          <div className={styles.customersHeader}>
            <div className={styles.customersWrap}>
              <Button variant="link" icon={<ArrowLeftIcon />} className={styles.customersWrapButton} onClick={onBack}>
                Back to Customer Domains
              </Button>
              <h3 className={styles.customersTitle}>({truncateString(selectedDomain?.dnsName, false, 80)})</h3>
            </div>
          </div>
        )}
        <Component
          domain={selectedDomain}
          isCompanyPage={false}
          userRole={userRole}
          refreshPageUrlCustomers={refreshPageUrlCustomers}
          onUpdateAppPublishing={onUpdateAppPublishing}
          onUpdateUser={onUpdateUser}
          showSentryUpdateMessageForAdAdmins={user.showSentryUpdateMessageForAdAdmins}
        />
      </div>
    </div>
  );
};
