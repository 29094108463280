import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'components/Link/Link';
import { SocialFbIcon, SocialLiIcon, SocialTwIcon, SocialYtIcon } from 'assets/img';
import styles from '../BrandedLinks.module.scss';

const Connect = ({ contactInformation }) => {
  const { facebookLink, twitterLink, youtubeLink, linkedinLink } = contactInformation;
  const validateText = (string) => {
    if (/(http(s?)):\/\//i.test(string)) {
      return string;
    }
    return `https://${string}`;
  };

  return (
    <ul className={cn(styles.linksDropdownList, styles.connect)}>
      {facebookLink && (
        <li><Link href={validateText(facebookLink)} external><SocialFbIcon />Facebook</Link></li>
      )}
      {twitterLink && (
        <li><Link href={validateText(twitterLink)} external><SocialTwIcon />Twitter</Link></li>
      )}
      {youtubeLink && (
        <li><Link href={validateText(youtubeLink)} external><SocialYtIcon />YouTube</Link></li>
      )}
      {linkedinLink && (
        <li><Link href={validateText(linkedinLink)} external><SocialLiIcon />LinkedIn</Link></li>
      )}
    </ul>
  );
};

Connect.propTypes = {
  contactInformation: PropTypes.object,
};

export default Connect;
