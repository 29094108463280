import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { MacIcon, SecureIcon, WinIcon, WorkspaceIcon, DownloadIcon } from 'assets/img';
import { memo } from 'react';
import { corpTechUser, custUser, freePersonalUser, personalUser, spTechUser } from 'consts';
import styles from './Download.module.scss';

const Downloads = ({ role, user, brandingData }) => {
  let downloadLinks = [
    {
      icon: <WorkspaceIcon />,
      label: 'Sentry Installer',
      link: user.downloadSentryLink,
      text: `Install on a Domain Controller or Member Server on Active Directory networks. 
      <br />Handles authentication and RDP brokering for domain-joined computers`,
    },
    {
      icon: <SecureIcon />,
      label: 'Secure Connect',
      link: user.downloadSecureConnectLink,
      text: 'Install on any Windows computer that requires self-brokering<br /> in Entra ID or Active Directory environment',
    },
    {
      icon: <WinIcon />,
      label: 'Windows Connector',
      link: user.downloadWinConnectorLink,
      text: 'Install on end user Windows computer. Allows end user<br /> to securely connect to Remote Windows computers and app',
    },
    {
      icon: <MacIcon />,
      label: 'Mac Connector',
      link: user.downloadMacConnectorLink,
      text: 'Install on end user Mac computer. Allows end user to<br /> securely connect to Remote Windows computers and app',
    },
  ];

  if ([custUser, spTechUser, corpTechUser].includes(role)) {
    downloadLinks = downloadLinks.filter((i) => i.label.includes('Connector'));
  }
  if ([personalUser, freePersonalUser].includes(role)) {
    downloadLinks = downloadLinks.filter((i) => i.label !== 'Sentry Installer');
  }

  return (
    <div
      className={cn(styles.downloads, {
        [styles.inverted]: brandingData?.isActive && brandingData?.useInvertedColorScheme,
      })}
      style={
        brandingData?.backGroundColor && brandingData?.isActive ? { background: brandingData.backGroundColor } : {}
      }>
      <div className={styles.downloadsTitle}>Downloads</div>
      <ul className={styles.downloadsList}>
        {downloadLinks.map((item) => (
          <li
            key={item.label}
            className={styles.downloadsListItem}
            id={`${item.label.split(' ').join('-')}-Download`}
            data-for={`${item.label.split(' ').join('-')}-Download`}
            data-tip={item.text}
            data-iscapture="true">
            <a
              href={item.link}
              className={cn(styles.downloadsListLink, { [styles.secureConnect]: item.label === 'Secure Connect' })}>
              <span>
                {item.icon} {item.label}
              </span>
              <DownloadIcon />
            </a>
            <ReactTooltip
              id={`${item.label.split(' ').join('-')}-Download`}
              place="right"
              type="light"
              effect="solid"
              multiline
              className={styles.downloadsListTooltip}
              delayShow={200}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

Downloads.propTypes = {
  role: PropTypes.string,
  user: PropTypes.object,
  brandingData: PropTypes.object,
};

export default memo(Downloads);
