import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import cn from 'classnames';
import { Button } from 'components';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styles from './BrandingFooter.module.scss';

const BrandingFooter = ({
  brandingStatus,
  brandingInfo,
  updateBrandingInfo,
  isBrandingInfoChanged,
  hexError,
  isDisableClearBranding,
  toggleShowModalBrandingClear,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const updateBranding = () => {
    setIsUploading(true);
    const postData = brandingInfo.modified;
    axios
      .post('/api/Company/UpdateBranding', postData)
      .then(({ data }) => {
        updateBrandingInfo({ init: data, modified: data });
        toast.success('Company branding has been updated', 3000);
      })
      .catch((err) => toast.error(err.response.data.message || err.message, 3000))
      .finally(() => setIsUploading(false));
  };

  const cancelAllChanges = () => {
    updateBrandingInfo(() => ({
      modified: brandingInfo.init,
      init: brandingInfo.init,
    }));
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <div>
          <Button
            variant="link"
            isDisabled={isDisableClearBranding || !brandingStatus}
            onClick={toggleShowModalBrandingClear}
            className={styles.footerLink}>
            Reset Branding
          </Button>
        </div>
        <div className={styles.footerActions}>
          <div className={styles.footerActionsButtons}>
            <Button
              variant="secondary"
              id="branding-cancel"
              size="40"
              isDisabled={!isBrandingInfoChanged}
              onClick={cancelAllChanges}>
              Cancel
            </Button>
            <Button
              variant="primary"
              id="branding-save"
              size="40"
              isDisabled={!isBrandingInfoChanged || hexError}
              isLoading={isUploading}
              onClick={updateBranding}
              className={cn(styles.footerActionsSave, { [styles.loading]: isUploading })}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

BrandingFooter.propTypes = {
  brandingStatus: PropTypes.bool,
  brandingInfo: PropTypes.object,
  updateBrandingInfo: PropTypes.func,
  isBrandingInfoChanged: PropTypes.bool,
  hexError: PropTypes.bool,
  isDisableClearBranding: PropTypes.bool,
  toggleShowModalBrandingClear: PropTypes.func,
};

export default BrandingFooter;
