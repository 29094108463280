/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DomainStatusMsg, Loader } from 'components';
import { queryFormatter } from 'utils';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import { useLocation } from 'react-router-dom';
import MfaMgmt from './components/MfaMgmt/MfaMgmt';
import PasswordMgmt from './components/PasswordMgmt/PasswordMgmt';
import GeoBlocking from './components/GeoBlocking/GeoBlocking';
import styles from './SecurityMgmt.module.scss';
import DesktopManagement from './components/DesktopManagement/DesktopManagement';

const SecurityMgmt = ({ userRole, domain, isCompanyPage, isDomainNotActivated }) => {
  const sentryDeleted = isSentryDeleted(domain);
  const [isLoading, setIsLoading] = useState(!sentryDeleted);
  const [mfaData, setMfaData] = useState(null);
  const [geoBlockingData, setGeoBlockingData] = useState(null);
  const { isDomainOnPremise } = getDomainADStatus(domain);
  const { search } = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const mfaDataBaseUrl = `/api/SecurityManagement/Mfa/Get?domainId=${domain.id}`;
  const geoBlockingDataBaseUrl = `/api/SecurityManagement/GeoBlocking/Get?domainId=${domain.id}`;

  const isVisiblePassMgmt = [techAdmin, spTechAdmin, spAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole);
  const isVisibleGeoBlocking = [spAdmin, corpAdmin].includes(userRole);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('tab') === 'desktop-management') {
      if (isDomainOnPremise && isVisiblePassMgmt) {
        setTabIndex(2);
      } else if (isDomainOnPremise) {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    }
    if (params.get('tab') === 'password-management') {
      setTabIndex(1);
    }
  }, [search]);

  const fetchMfaData = async () => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
        viewBy: 'All',
      };
      const resp = await axios.get(queryFormatter(mfaDataBaseUrl, queryConfig));
      const pageMeta = resp?.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;
      if (resp.status === 204) {
        setMfaData({ isNoData: true });
      } else {
        setMfaData({
          data: resp.data,
          pageMeta,
          queryConfig,
        });
      }
    } catch (error) {
      setMfaData({ isError: true, error: error.message });
    }
  };

  const fetchGeoBlockingData = async () => {
    try {
      const queryConfig = {
        orderBy: 'countryName asc',
      };
      const resp = await axios.get(queryFormatter(geoBlockingDataBaseUrl, queryConfig));
      const pageMeta = resp?.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;
      setGeoBlockingData({ data: resp.data, pageMeta, queryConfig });
    } catch (error) {
      setGeoBlockingData({ isError: true, error: error.message });
    }
  };

  const fetchData = async () => {
    if (isDomainOnPremise) {
      await fetchMfaData();
    }
    await fetchGeoBlockingData();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <Loader />;

  if (isDomainNotActivated || sentryDeleted) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Security Management" />;
  }

  return (
    <div className={styles.securityMgmt}>
      <h3 className={styles.securityMgmtTitle}>Security Management</h3>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {isDomainOnPremise && <Tab>MFA Management</Tab>}
          {isVisiblePassMgmt && isDomainOnPremise && <Tab>Password Management</Tab>}
          <Tab>Desktop Management</Tab>
          {(isVisibleGeoBlocking || (userRole === spTechAdmin && !isCompanyPage)) && <Tab>Geo-Blocking</Tab>}
        </TabList>
        {isDomainOnPremise && (
          <TabPanel>
            <MfaMgmt mfaData={mfaData} onUpdate={setMfaData} baseUrl={mfaDataBaseUrl} />
          </TabPanel>
        )}
        {isVisiblePassMgmt && isDomainOnPremise && (
          <TabPanel>
            <PasswordMgmt domainId={domain.id} />
          </TabPanel>
        )}
        <TabPanel>
          <DesktopManagement isDomainOnPremise={isDomainOnPremise} />
        </TabPanel>
        {(isVisibleGeoBlocking || (userRole === spTechAdmin && !isCompanyPage)) && (
          <TabPanel>
            <GeoBlocking
              dnsName={domain.dnsName}
              geoBlockingData={geoBlockingData}
              baseUrl={geoBlockingDataBaseUrl}
              domainId={domain.id}
              onUpdate={setGeoBlockingData}
              isCompanyPage={isCompanyPage}
            />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

SecurityMgmt.propTypes = {
  userRole: PropTypes.string,
  domain: PropTypes.object.isRequired,
  isCompanyPage: PropTypes.bool,
  isDomainNotActivated: PropTypes.bool,
};

export default SecurityMgmt;
