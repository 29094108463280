import PropTypes from 'prop-types';
import cn from 'classnames';
import { AngleDownIcon, CompanyIcon, CustomersIcon, HeaderUserIcon, LogoIcon, PlusIcon, WorkspaceIcon } from 'assets/img';
import WorkspacePreviewImage from 'assets/img/workspace-preview.svg';
import { convertHex } from 'pages/Branding/helpers/colorsHelper';
import styles from './WorkspacePreview.module.scss';

const WorkspacePreview = ({ brandingInfo, invertedColor }) => {
  const color = invertedColor ? { color: '#000' } : { color: '#fff' };
  const fill = invertedColor ? { fill: '#000' } : { fill: '#fff' };

  return (
    <div className={styles.preview}>
      <nav className={styles.previewNav} style={brandingInfo?.modified?.backGroundColor ? { background: convertHex(brandingInfo.modified.backGroundColor.slice(1)) } : {}}>
        <div>
          {brandingInfo?.modified?.logo ? (
            <img className={cn(styles.previewNavLogo, styles.previewNavLogoCustom)} src={`data:image/png;base64,${brandingInfo.modified.logo}`} width="46" alt="" />
          ) : (
            <LogoIcon className={cn(styles.previewNavLogo, styles.previewNavLogoImage)} />
          )}
          <ul className={styles.previewNavMenu} style={color}>
            <li className={cn(styles.previewNavMenuItem, styles.active)}><WorkspaceIcon style={fill} />My Workspace</li>
            <li className={styles.previewNavMenuItem}><CompanyIcon style={fill} />Company<PlusIcon style={fill} className={styles.previewNavMenuItemPlus} /></li>
            <li className={styles.previewNavMenuItem}><CustomersIcon style={fill} />Customers</li>
          </ul>
        </div>
        <div className={styles.previewNavFooter}>
          <div className={styles.previewNavFooterCopyright} style={color}>Powered by trugrid.com</div>
          <div className={styles.previewNavFooterDownloads} style={color}>Downloads<AngleDownIcon style={fill} /></div>
        </div>
      </nav>
      <main className={styles.previewMain}>
        <div className={styles.previewMainWrap}>
          <div className={styles.previewMainTimer}>For your security, this website will be logged off after <span>15:00</span> mins of inactivity</div>
          <div className={styles.previewMainAvatarWrap}>
            <div className={styles.previewMainAvatar}><HeaderUserIcon /></div><AngleDownIcon className={styles.previewMainAvatarDown} />
          </div>
        </div>
        <div>
          <img className={styles.previewMainImage} src={WorkspacePreviewImage} alt="" />
        </div>
      </main>
    </div>
  );
};
WorkspacePreview.propTypes = {
  brandingInfo: PropTypes.object,
  invertedColor: PropTypes.bool,
};

export default WorkspacePreview;
