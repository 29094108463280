/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { UserContext, getUserRole, notify, objectsAreNotEqual } from 'utils';
import { toast } from 'react-toastify';
import { Loader, RouteLeavingGuard, StatusMessage } from 'components';
import { TABS, techAdmin } from 'consts';
import axios from 'config/customAxios';
import {
  checkIsCustomDomain,
  checkIsMultipleSettings,
  checkIsDropdownRestricted,
  checkIsShowCustDomains,
  checkIsHideZeroTrust,
} from './helpers/settingsHelper';
import SettingsHeader from './components/SettingsHeader/SettingsHeader';
import ModalSaveAs from './components/ModalSaveAs/ModalSaveAs';
import ModalResetAll from './components/ModalResetAll/ModalResetAll';
import ModalConfirmChangeDomain from './components/ModalConfirmChangeDomain/ModalConfirmChangeDomain';
import ModalResetSingleDomain from './components/ModalResetSingleDomain/ModalResetSingleDomain';
import { addCustomResolutions } from './utils/addCustomResolutions';
import { onChangeSettings } from './utils/onChangeSettings';
import { onDiscardChanges } from './utils/onDiscardChanges';
import { onCustomResSave } from './utils/onCustomResSave';
import { onChangeDomain } from './utils/onChangeDomain';
import { onSaveSettings } from './utils/onSaveSettings';
import { onResetSingleSettings } from './utils/onResetSingleSettings';
import styles from './Settings.module.scss';
import { renderTabContent } from './helpers/renderTabContent';

const Settings = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isDomainLoading, setIsDomainLoading] = useState(false);
  const [tabsContent, setTabsContent] = useState(TABS);
  const [domains, setDomains] = useState(null);
  const [domainCurrent, setDomainCurrent] = useState('My Settings');
  const [domainSettings, setDomainSettings] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeDomainValue, setChangeDomainValue] = useState(null);
  const [showModalConfirmChangeDomain, setShowModalConfirmChangeDomain] = useState(false);
  const [domainToChange, setDomainToChange] = useState(null);
  const [showModalResetAll, setShowModalResetAll] = useState(false);
  const [showModalResetSingleDomain, setShowModalResetSingleDomain] = useState(false);
  const [showModalSaveAs, setShowModalSaveAs] = useState(false);

  const role = getUserRole(user);
  const isMultipleSettings = checkIsMultipleSettings(role);
  const isDropdownRestricted = checkIsDropdownRestricted(role);
  const isCustomDomain = checkIsCustomDomain(domainCurrent);
  const isShowCustDomains = checkIsShowCustDomains(role);

  const [isZeroTrustMsgOpen, setIsZeroTrustMsgOpen] = useState(true);
  const [isCustomResOpen, setIsCustomResOpen] = useState(false);
  const isHideZeroTrust = checkIsHideZeroTrust(role);

  const toggleShowModalConfirmChangeDomain = (domainName) => {
    if (!showModalConfirmChangeDomain) {
      setShowModalConfirmChangeDomain(true);
      setDomainToChange(domainName);
    } else {
      setDomainToChange(null);
      setShowModalConfirmChangeDomain(false);
    }
  };
  const toggleShowModalResetAll = () => setShowModalResetAll(!showModalResetAll);
  const toggleShowModalResetSingleDomain = () => setShowModalResetSingleDomain(!showModalResetSingleDomain);
  const toggleShowModalSaveAs = () => setShowModalSaveAs(!showModalSaveAs);

  const handleAddCustomResolutions = (data) => {
    const tabsContentNew = addCustomResolutions(tabsContent, data);
    setTabsContent(tabsContentNew);
  };

  const loadInitSettings = () => {
    const URLS = [
      `/api/Connectors/GetRdpSessionConfiguration?userId=${user.id}`,
      '/api/Domains/GetCustomerDomains?onlyShortData=true&onlyActiveDomains=true',
    ];

    if (!isShowCustDomains) URLS.pop();

    Promise.allSettled(URLS.map((url) => axios.get(url)))
      .then((results) => {
        if (results[0].status === 'fulfilled') {
          const settings = results[0].value.data.rdpConfiguration;

          if (settings) {
            handleAddCustomResolutions(settings);
            setDomainSettings({
              isError: false,
              isModified: false,
              initial: { ...settings },
              modified: { ...settings },
            });
          } else {
            setDomainSettings({ isError: true, error: `Sorry, there no settings for <b>${domainCurrent}</b>` });
          }
        } else {
          setDomainSettings({ isError: true, error: results[0].reason.message });
        }

        // TODO: rewrite to [{domainDnsName: domainId}] format and make changes where needed
        const domainsNew =
          role === techAdmin ? {} : { [user.dnsDomainName]: { id: user.domainId, dnsName: user.dnsDomainName } };

        if (isShowCustDomains && results[1].status === 'fulfilled') {
          results[1].value.data.forEach((d) => (domainsNew[d.dnsName] = d));
        }

        setDomains(domainsNew);
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    loadInitSettings();
  }, []);

  const onOpenShowModalSaveAs = (domainName) => {
    setChangeDomainValue(domainName);
    toggleShowModalSaveAs();
  };

  const handleChangeDomain = async (domainName, discard) => {
    if (!discard && domainSettings.isModified && objectsAreNotEqual(domainSettings)) {
      toggleShowModalConfirmChangeDomain(domainName);
    } else {
      try {
        setIsDomainLoading(true);
        const { settings, newDomainSettings } = await onChangeDomain(domainName, user, domains);
        handleAddCustomResolutions(settings);
        setDomainSettings(newDomainSettings);
      } catch (err) {
        setDomainSettings({
          isError: false,
          error: err.message,
        });
      } finally {
        setDomainCurrent(domainName);
        setIsDomainLoading(false);
        setShowModalConfirmChangeDomain(false);
      }
    }
  };

  const onModalClose = (newDomainValue) => {
    setIsModalOpen(false);
    if (newDomainValue && !newDomainValue.target) {
      handleChangeDomain(newDomainValue, true);
    }
  };

  const onCloseShowModalSaveAs = (domainName) => {
    if (domainName && !domainName.target) {
      handleChangeDomain(domainName, true);
    }
    toggleShowModalSaveAs();
  };

  const handleChangeSettings = (label, value) => {
    const domainSettingsNew = onChangeSettings(label, value, domainSettings);
    setDomainSettings(domainSettingsNew);
  };

  const handleCustomResSave = (customRes) => {
    const { tabsContentNew, domainSettingsNew } = onCustomResSave(tabsContent, domainSettings, customRes);
    setTabsContent(tabsContentNew);
    setDomainSettings(domainSettingsNew);
  };

  const handleDiscardChanges = (domainName) => {
    const domainSettingsNew = onDiscardChanges(domainSettings);
    setDomainSettings(domainSettingsNew);
    setIsModalOpen(false);
    handleChangeDomain(domainName, true);
  };

  const handleSaveSingleSettings = async (value, isChangeDomain) => {
    if (isModalOpen) {
      onModalClose();
    }
    if (value === 'Save As') {
      onOpenShowModalSaveAs(isChangeDomain);
    } else {
      try {
        setIsSaving(true);
        const domainSettingsNew = await onSaveSettings(domainSettings, domains, domainCurrent, isCustomDomain);
        setDomainSettings(domainSettingsNew);
        toast.success('Settings saved', 3000);
        if (isChangeDomain) {
          handleChangeDomain(value, true);
        }
      } catch (err) {
        notify.error(err.response?.data || err.message);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const onResetSettings = () => {
    if (isMultipleSettings) {
      toggleShowModalResetAll();
    } else {
      toggleShowModalResetSingleDomain();
    }
  };

  const handleResetSingleSettings = async () => {
    try {
      setIsResetting(true);
      const newDomainSettings = await onResetSingleSettings(user, isCustomDomain, domains, domainCurrent);
      setDomainSettings(newDomainSettings);
      setTabsContent(TABS);
      notify.success('Settings reset');
    } catch (err) {
      notify.error(err.response?.data || err.message);
    } finally {
      setIsLoading(true);
      loadInitSettings();
      setIsResetting(false);
      setShowModalResetSingleDomain(false);
    }
  };

  const onResetAsSettings = (domains, isApplyToMyself, applyToDomains) =>
    new Promise((resolve) => {
      setIsResetting(true);
      const isApplyToDomains = domains.length && applyToDomains;

      const applyTo = [];
      if (isApplyToMyself) {
        applyTo.push(user.id);
      }
      if (isApplyToDomains) {
        domains.map((d) => applyTo.push(d.id));
      }

      axios({
        method: 'post',
        url: '/api/Connectors/ResetRdpConfiguration',
        data: applyTo,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          setDomainSettings({
            isError: false,
            isModified: false,
            initial: { ...res.data },
            modified: { ...res.data },
          });
          setTabsContent(TABS);
          if (isApplyToMyself && !isApplyToDomains) {
            toast.success('Settings reset');
          } else {
            toast.success(`Settings reset for the selected domain${domains.length > 1 ? 's' : ''} `);
          }
        })
        .catch((err) => toast.error(err.response?.data || err.message, 3000))
        .finally(() => {
          setIsResetting(false);
          resolve();
        });
    });

  const onSaveAsSettings = (domains, isApplyToMyself, applyToDomains) =>
    new Promise((resolve) => {
      setIsSaving(true);

      const userLink = '/api/Connectors/UpdateUserRdpConfigs';
      const domainsLink = '/api/Connectors/UpdateRdpConfigsForDomains';
      const settings = domainSettings.modified;
      const isApplyToDomains = domains.length && applyToDomains;

      if (isApplyToMyself && !isApplyToDomains) {
        axios
          .post(userLink, settings)
          .then(() => {
            setDomainSettings({
              isError: false,
              isModified: false,
              initial: { ...settings },
              modified: { ...settings },
            });
            toast.success('Settings saved');
          })
          .catch((err) => toast.error(err.response?.data || err.message, 3000))
          .finally(() => {
            setIsSaving(false);
            resolve();
          });
      } else if (!isApplyToMyself && isApplyToDomains) {
        axios
          .post(domainsLink, { Configuration: settings, DomainIds: domains.map((d) => d.id) })
          .then(() => {
            setDomainSettings({
              isError: false,
              isModified: false,
              initial: { ...settings },
              modified: { ...settings },
            });
            toast.success(`Settings saved for the selected domain${domains.length > 1 ? 's' : ''} `);
          })
          .catch((err) => toast.error(err.response?.data || err.message, 3000))
          .finally(() => {
            setIsSaving(false);
            resolve();
          });
      } else if (isApplyToMyself && isApplyToDomains) {
        Promise.allSettled([
          axios.post(userLink, settings),
          axios.post(domainsLink, { Configuration: settings, DomainIds: domains.map((d) => d.id) }),
        ])
          .then(() => {
            setDomainSettings({
              isError: false,
              isModified: false,
              initial: { ...settings },
              modified: { ...settings },
            });
            toast.success(`Settings saved for the selected domain${domains.length > 1 ? 's' : ''} `);
          })
          .finally(() => {
            setIsSaving(false);
            resolve();
          });
      }
    });

  if (isLoading) {
    return (
      <div className={styles.settings}>
        <Loader />
      </div>
    );
  }

  if (domainSettings.isError) {
    return (
      <div className={styles.settings}>
        <StatusMessage error>{domainSettings.error}</StatusMessage>
      </div>
    );
  }

  return (
    <div className={styles.settings}>
      <div>
        <SettingsHeader
          role={role}
          domains={domains}
          domainCurrent={domainCurrent}
          isSaving={isSaving}
          isMultipleSettings={isMultipleSettings}
          isDisabled={!objectsAreNotEqual(domainSettings)}
          onChangeDomain={handleChangeDomain}
          onSaveSettings={handleSaveSingleSettings}
          onResetSettings={onResetSettings}
        />

        <Tabs className={styles.reactTabs}>
          <div className={styles.reactTabsWrapper}>
            {Object.keys(tabsContent).map((key) => (
              <TabPanel key={key}>
                {isDomainLoading ? (
                  <Loader />
                ) : (
                  Object.entries(tabsContent[key]).map((item) =>
                    renderTabContent(
                      domainSettings.modified,
                      handleChangeSettings,
                      handleCustomResSave,
                      domainCurrent,
                      item[1],
                      item[0],
                      setIsCustomResOpen,
                      isCustomResOpen,
                      isHideZeroTrust,
                      setIsZeroTrustMsgOpen,
                      isZeroTrustMsgOpen,
                    ),
                  )
                )}
              </TabPanel>
            ))}
          </div>
          <TabList>
            <Tab>
              <span id="setting-general">General</span>
            </Tab>
            <Tab>
              <span id="setting-display">Display</span>
            </Tab>
            <Tab>
              <span id="setting-device-redirection">Device redirection</span>
            </Tab>
            <Tab>
              <span id="setting-audio-video">Audio and video</span>
            </Tab>
          </TabList>
        </Tabs>

        <RouteLeavingGuard
          when={domainSettings.isModified}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => objectsAreNotEqual(domainSettings)}
          modalConfig={{
            label: 'Leave Modal',
            title: 'Discard changes',
            message: 'Do you really want to leave and discard changes?',
            btnClose: 'Stay on Page',
            btnAccept: 'Leave',
          }}
        />
      </div>
      {showModalConfirmChangeDomain && (
        <ModalConfirmChangeDomain
          isOpen={showModalConfirmChangeDomain}
          onRequestClose={toggleShowModalConfirmChangeDomain}
          onDiscardChanges={handleDiscardChanges}
          domainCurrent={domainCurrent}
          value="Confirm"
          onSaveSettings={handleSaveSingleSettings}
          isCustomDomain={isCustomDomain}
          domainToChange={domainToChange}
        />
      )}
      {showModalResetAll && (
        <ModalResetAll
          isOpen={showModalResetAll}
          onRequestClose={toggleShowModalResetAll}
          domains={Object.values(domains)}
          userDomainId={user.domainId}
          domainCurrent={domainCurrent}
          isDropdownRestricted={isDropdownRestricted}
          onResetAs={onResetAsSettings}
        />
      )}
      {showModalResetSingleDomain && (
        <ModalResetSingleDomain
          isOpen={showModalResetSingleDomain}
          onRequestClose={toggleShowModalResetSingleDomain}
          onResetSingleSettings={handleResetSingleSettings}
          isResetting={isResetting}
        />
      )}
      {showModalSaveAs && (
        <ModalSaveAs
          isOpen={showModalSaveAs}
          onRequestClose={onCloseShowModalSaveAs}
          domains={Object.values(domains)}
          role={role}
          userDomainId={user.domainId}
          domainCurrent={domainCurrent}
          isDropdownRestricted={isDropdownRestricted}
          changeDomainValue={changeDomainValue}
          onSaveAs={onSaveAsSettings}
        />
      )}
    </div>
  );
};

export default Settings;
