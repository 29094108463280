import PropTypes from 'prop-types';
import { Modal } from 'components';
import { useState } from 'react';
import axios from 'config/customAxios';
import { notify } from 'utils';
import { AttentionIcon } from 'assets/img';
import styles from './ModalBrandingClear.module.scss';

const ModalBrandingClear = ({ isOpen, onRequestClose, updateBrandingInfo, domainId, domainName }) => {
  const [isUploading, setIsUploading] = useState(false);

  const clearBranding = () => {
    setIsUploading(true);
    axios
      .post('api/Company/ResetBranding', domainId)
      .then(({ data }) => {
        updateBrandingInfo({ init: data, modified: data });
        setIsUploading(false);
        notify.success(`Branding is reset for ${domainName}`);
        onRequestClose();
      })
      .catch((err) => {
        notify.error(err.response.data || err.message);
        setIsUploading(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.modalTitle}>
          <AttentionIcon /> Reset Branding?
        </div>
      }
      actionFunc={clearBranding}
      actionLoading={isUploading}
      actionTitle="Reset"
      variantBtn="delete">
      By clicking <strong>RESET</strong>, all customized data will be cleared.
    </Modal>
  );
};

ModalBrandingClear.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  updateBrandingInfo: PropTypes.func,
  domainId: PropTypes.string,
  domainName: PropTypes.string,
};

export default ModalBrandingClear;
