import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { BrandingContext } from 'utils';
import WorkspaceItems from './components/WorkspaceItems';
import CompanyItems from './components/CompanyItems';
import CustomerItems from './components/CustomerItems';

const NavbarItem = ({
  itemId,
  role,
  domainStatus,
  isCompanyOpen,
  onCompanyToggle,
  onNotCompanyClick,
  blockLeftMenu,
}) => {
  const { brandingData } = useContext(BrandingContext);
  const location = useLocation();

  if (itemId === 'workspace') {
    return (
      <WorkspaceItems
        location={location}
        onNotCompanyClick={onNotCompanyClick}
        isBrandingActive={brandingData?.isActive}
        isInvertedColors={brandingData?.useInvertedColorScheme}
        blockLeftMenu={blockLeftMenu}
      />
    );
  }

  if (itemId === 'company') {
    return (
      <CompanyItems
        location={location}
        role={role}
        isCompanyOpen={isCompanyOpen}
        domainStatus={domainStatus}
        onCompanyToggle={onCompanyToggle}
        isBrandingActive={brandingData?.isActive}
        isInvertedColors={brandingData?.useInvertedColorScheme}
        blockLeftMenu={blockLeftMenu}
      />
    );
  }

  if (itemId === 'customers') {
    return (
      <CustomerItems
        location={location}
        role={role}
        domainStatus={domainStatus}
        onNotCompanyClick={onNotCompanyClick}
        isBrandingActive={brandingData?.isActive}
        isInvertedColors={brandingData?.useInvertedColorScheme}
        blockLeftMenu={blockLeftMenu}
      />
    );
  }
};

NavbarItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  domainStatus: PropTypes.number.isRequired,
  isCompanyOpen: PropTypes.bool.isRequired,
  onCompanyToggle: PropTypes.func.isRequired,
  onNotCompanyClick: PropTypes.func.isRequired,
  blockLeftMenu: PropTypes.bool.isRequired,
};

NavbarItem.displayName = 'NavbarItem';

export default memo(NavbarItem);
