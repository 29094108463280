import PropTypes from 'prop-types';
import cn from 'classnames';
import { SwitchCheckbox, Tooltip } from 'components';
import { TooltipIcon } from 'assets/img';
import styles from './BrandingHeader.module.scss';

const BrandingHeader = ({ brandingStatus, toggleBrandingStatus, brandingInfo, updateBrandingInfo }) => {
  const toggleApplyForConnectors = (e) => {
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, applyToDesktopConnectors: e.target.checked },
      init: brandingInfo.init,
    }));
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerWrap}>
        <div className={styles.headerCheckbox}>
          <div className={styles.headerCheckboxWrap}>
            <p className={styles.headerCheckboxTitle}>Branding Status</p>
            <Tooltip
              id="info-tooltip"
              place="top"
              className={styles.headerCheckboxTooltip}
              wrapClassName={styles.headerCheckboxTooltipWrap}
              content="Customize how your brand appears on TruGrid">
              <TooltipIcon />
            </Tooltip>
          </div>
          <SwitchCheckbox id="branding-toggle" checked={brandingStatus} onChange={toggleBrandingStatus} />
          <span className={cn(styles.headerCheckboxText, { [styles.active]: brandingStatus })}>
            {brandingStatus ? 'ON' : 'OFF'}
          </span>
        </div>
      </div>
      <div className={cn(styles.headerCheckbox, { [styles.disabled]: !brandingInfo.modified.isActive })}>
        <p>Apply to Desktop Connectors</p>
        <SwitchCheckbox
          id="apply-for-desktop-connectors"
          checked={brandingInfo?.modified?.applyToDesktopConnectors || false}
          onChange={toggleApplyForConnectors}
        />
        <span
          className={cn(styles.headerCheckboxText, {
            [styles.active]: brandingInfo?.modified?.applyToDesktopConnectors || false,
          })}>
          {brandingInfo?.modified?.applyToDesktopConnectors || false ? 'ON' : 'OFF'}
        </span>
      </div>
    </header>
  );
};

BrandingHeader.propTypes = {
  brandingStatus: PropTypes.bool,
  toggleBrandingStatus: PropTypes.func,
  brandingInfo: PropTypes.object,
  updateBrandingInfo: PropTypes.func,
};

export default BrandingHeader;
